import React, { useRef, useState } from 'react';
import { createStyles, NumberInput, ActionIcon, rem } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${rem(6)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,
    backgroundColor: '#F3FDA6',

    '&:focus-within': {
      borderColor: '#f2ba1f',
    },
  },

  control: {
    backgroundColor: '#ED7D02',
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,

    '&:disabled': {
      borderColor: theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: 'transparent',
    },
  },

  input: {
    textAlign: 'center',
    paddingRight: `${theme.spacing.sm} !important`,
    paddingLeft: `${theme.spacing.sm} !important`,
    height: rem(30),
    flex: 1,
  },
}));

export function QuantityInput({ min = 1, max = 4, onQuantityChange }) {
  const { classes } = useStyles();
  const handlers = useRef(null);
  const [value, setValue] = useState(1);

  const handleChange = (newValue) => {
    setValue(newValue);
    onQuantityChange(newValue);
  };

  return (
    <div className={classes.wrapper}>
      <ActionIcon
        size={32}
        variant="transparent"
        onClick={() => handlers.current.decrement()}
        disabled={value === min}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <IconMinus size="1.5rem" stroke={1.5} color="black" />
      </ActionIcon>

      <NumberInput
        variant="unstyled"
        min={min}
        max={max}
        handlersRef={handlers}
        value={value}
        onChange={handleChange}
        classNames={{ input: classes.input, // Add a style property to the classNames prop
                      label: classes.label }}
        size={25}// Set the font size of the number to 1.2rem
      />

      <ActionIcon
        size={32}
        variant="transparent"
        onClick={() => handlers.current.increment()}
        disabled={value === max}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <IconPlus size="1.5rem" stroke={1.5} color="black" />
      </ActionIcon>
    </div>
  );
}

