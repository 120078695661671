import { Button, Center, Container, Grid, Paper, SimpleGrid, Skeleton, Title } from '@mantine/core';
import { QuantityInput } from './input.js';
import { CalculatingButton } from './CalculatingButton.js';
import { useState } from 'react';

export function LayoutGrid() {
  const [displayText, setDisplayText] = useState("That is a shame, looks like there are a few to many players currently. Guess you'll have to jump in Solo Queue - be sure to not kill yourself");
  const [inputValue, setInputValue] = useState(0);

  const handleQuantityChange = (value) => {
    setInputValue(value);
  };

  const handleCalculateClick = () => {
    if (inputValue > 1) {
      setDisplayText("Would you look at that! Alex is keen to join, aren't you lucky! Be sure to listen to his calls, he is correct in most instances.");
    } else if (inputValue === 1) {
      setDisplayText("That is a shame, looks like there are a few to many players. Guess you'll have to jump in Solo Queue - be sure to not kill yourself");
    }
  };

  return (
    <Container my="md" style={{borderRadius:"10px", maxWidth: '450px', backgroundColor: '#adadad' }}>
      <SimpleGrid cols={1} spacing="md">
        <Grid gutter="md" justify="center" justifyContent="center">
          <Grid.Col>
            <Center maw={340} mx="auto">
              <Title order={1}>
                Will Alex join you on League of Legends?
              </Title>
            </Center>
          </Grid.Col>

          <Grid.Col>
            <Center maw={450} mx="auto">
              <Title order={4}>
                Excluding Alex, enter the number of players currently wanting to play League of Legends?
              </Title>
            </Center>
          </Grid.Col>
          <Grid.Col span={6}>
            <QuantityInput onQuantityChange={handleQuantityChange} />
          </Grid.Col>
          <Grid.Col justifyContent="centre">
              <Center maw={600} mx="auto">
                <Button color="orange" size="lg" onClick={handleCalculateClick}>
                  Calculate
                </Button>
              </Center>
          </Grid.Col>
          <Grid.Col>
            <Center mx="auto">
              <p>{displayText}</p>
            </Center>
          </Grid.Col>
        </Grid>
      </SimpleGrid>
    </Container>
  );
}
