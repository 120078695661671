import { LayoutGrid } from './LayoutGrid.js';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LayoutGrid />
      </header>
    </div>
  );
}

export default App;
